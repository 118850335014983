<template>
    <div style="padding-bottom: 20px;">
        <el-form label-width="150px" :model="dataform" ref="ruleForm" :rules="rules">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="CODE" prop="code">
                        <el-input v-model="dataform.code" type="text" :show-word-limit="true"
                                  maxlength="20"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="名称" prop="name">
                        <el-input v-model="dataform.name" type="text" :show-word-limit="true" maxlength="20"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="Qoo10 ユーザー名" prop="userId">
                        <el-input v-model="dataform.userId" type="text" :show-word-limit="true"
                                  maxlength="20"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="識別子" prop="identifier">
                        <el-input v-model="dataform.identifier" type="text" :show-word-limit="true"
                                  maxlength="40"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="フィールド１">
                        <el-input v-model="dataform.column1" type="text" :show-word-limit="true"
                                  maxlength="20"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="フィールド2">
                        <el-input v-model="dataform.column2" type="text" :show-word-limit="true"
                                  maxlength="20"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-form-item label="Qoo10 APIKey">
                    <el-input v-model="dataform.q10ApiKey" type="textarea" :show-word-limit="true"
                              rows="3" maxlength="200"></el-input>
                </el-form-item>
            </el-row>
            <el-form-item label="備考">
                <el-input v-model="dataform.remark" type="textarea" :show-word-limit="true" maxlength="500"
                          rows="4"></el-input>
            </el-form-item>

            <el-form-item label="メール通知" prop="id" style="text-align: left;">
                <template class="box" v-for="(item, index) in dataform.mails">
                    <div>
                        <el-input type="text" v-model="dataform.mails[index]" style="margin-bottom: 8px; width: 300px;"
                                  :disabled="index < dataform.mails.length - 1"
                                  :key="index"></el-input>
                        <span class="del-box" @click="deleteName(index)">
                            <i class="el-icon-delete"></i> 削除
                        </span>
                    </div>
                </template>
                <span class="add-box">
                    <span @click="addName">+ ほかのメール</span>
                </span>
            </el-form-item>
        </el-form>
        <el-row>
            <el-button class="btn-width-big" @click="cancel" icon="el-icon-back">キャンセル</el-button>
            <el-button class="btn-width-big" @click="save" type="success" icon="el-icon-check">保存</el-button>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "supplierAdd",
        props: ['types', 'info'],
        data() {
            return {
                dataform: {
                    supplierId: '',
                    code: '',
                    name: '',
                    userId: '',
                    identifier: '',
                    q10ApiKey: '',
                    column1: '',
                    column2: '',
                    remark: '',
                    mails: [],
                },
                rules: {
                    code: [
                        {required: true, message: 'CODE', trigger: 'blur'},
                    ],
                    name: [
                        {required: true, message: '名称', trigger: 'blur'},
                        {min: 1, max: 20, message: '長さは 1 ～ 20 個字符', trigger: 'blur'}
                    ],
                    userId: [
                        {required: true, message: 'Qoo10 ユーザー名', trigger: 'blur'},
                        {min: 1, max: 20, message: '長さは 1 ～ 20 個字符', trigger: 'blur'}
                    ],
                    identifier: [
                        {required: true, message: '識別子', trigger: 'blur'},
                        {min: 1, max: 40, message: '長さは 1 ～ 40 個字符', trigger: 'blur'}
                    ]
                }
            }
        },
        mounted() {
            for (let key in this.dataform) {
                if (this.types === 'edit') {
                    this.dataform[key] = this.info[key]
                    if (key === 'mails') {
                        let mails = this.info['failedNotificationMail'];
                        if (mails) {
                           this.dataform.mails = JSON.parse(mails)
                        } else {
                            this.dataform.mails = ['']
                        }
                    }
                } else {
                    this.dataform[key] = '';
                    if (key === 'mails') {
                        this.dataform[key] = ['']
                    }
                }
            }
        },
        methods: {
            deleteName(index) {
                this.dataform.mails.splice(index, 1);
                if (!this.dataform.mails || !this.dataform.mails.length) {
                    this.dataform.mails = ['']
                }
            },
            checkEmail(email) {
                var myReg = /^[a-zA-Z0-9]+((_|-|\.)[a-zA-Z0-9]+)*@([a-zA-Z0-9]+(-|\.))+[a-zA-Z]{2,5}$/;
                return myReg.test(email);
            },
            addName() {
                if (!this.jurMails()) {
                    return;
                }
                (!this.dataform.mails.length || this.dataform.mails[this.dataform.mails.length - 1]) && this.dataform.mails.push('');
            },
            // TODO 中文
            /**
             * 邮箱校验
             **/
            jurMails() {
                let nowEml = this.dataform.mails[this.dataform.mails.length - 1];
                if (!nowEml) {
                    this.$message.warning("メールをご記入ください")
                    return false;
                }
                if (!this.checkEmail(nowEml)) {
                    this.$message.warning(  "メール ["+nowEml+"] のフォーマットが間違っている")
                    return false;
                }
                return true;
            },
            /**
             * キャンセル
             */
            cancel() {
                this.$emit('cancel')
            },
            /**
             * 保存
             */
            async save() {
                this.$refs.ruleForm.validate(async (valid) => {
                    if (!valid) {
                        return;
                    }
                    if (!this.jurMails()) {
                        return;
                    }
                    this.dataform['failedNotificationMail'] = this.dataform.mails.join(',')
                    let array =this.dataform['failedNotificationMail'].split(",")
                    let param = Object.assign(this.dataform,{failedNotificationMail:JSON.stringify(array)});
                    if (this.types === 'edit') {
                        let res = await this.$http.supplier.update(param);   
                        if (res.code === 200) {
                            this.$emit('save')
                            this.$message.success(res.msg)
                        }
                    } else {
                        let res = await this.$http.supplier.save(param);
                        if (res.code === 200) {
                            this.$emit('save')
                            this.$message.success(res.msg)
                        }
                    }

                })
            }
        }
    }
</script>

<style lang="scss" scoped>
  .box {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .del-box {
    cursor: pointer;
    width: 50px;
    display: inline-block;
    color: rgba(217, 0, 27, 0.996078431372549) !important;;
    margin-left: 8px;
    position: absolute;
  }

  .add-box {
    display: block;
    color: rgba(24, 144, 255, 1) !important;
    text-align: left;

    span {
      cursor: pointer;
    }
  }

</style>
