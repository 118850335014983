<template>
    <div style="padding-bottom: 20px;">
        <el-form label-width="100px" :model="form" ref="form" :rules="rules">
            <el-form-item label="タスク名称" prop="jobId">
                <el-select v-model="form.jobId" placeholder="タスクを選択" style="width: 100%;">
                    <el-option v-for="item in options" :key="item.jobId"
                               :label="item.jobName"
                               :value="item.jobId">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="識別子" prop="identifier">
                <el-input v-model="form.identifier" type="text" :show-word-limit="true" maxlength="20"
                          placeholder="英語の識別子を入力"></el-input>
            </el-form-item>
            <el-form-item label="請求URL" prop="requestUrl">
                <el-input v-model="form.requestUrl" type="text" :show-word-limit="true"
                          placeholder="APIアドレスを入力"></el-input>
            </el-form-item>
            <el-form-item label="パラメータ" prop="requestParameter">
                <el-input v-model="form.requestParameter" type="textarea" :show-word-limit="true" maxlength="500"
                          rows="10"></el-input>
            </el-form-item>
        </el-form>
        <el-row>
            <el-button class="btn-width-big" @click="cancel" icon="el-icon-back">キャンセル</el-button>
            <el-button class="btn-width-big" @click="save" type="success" icon="el-icon-check">保存</el-button>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "function-edit",
        props: ['info'],
        data() {
            var isJsonString = (rule, value, callback) => {
                try {
                    const toObj = JSON.parse(value)
                    if (toObj && typeof toObj === 'object') {
                        callback();
                        return;
                    }
                } catch {}
                callback(new Error('フォーマットエラー'));
            };
            return {
                options: [],
                form: {
                    supplierId: '',
                    functionId: '',
                    jobId: '',
                    identifier: '',
                    requestUrl: '',
                    requestParameter: ''
                },
                rules: {
                    jobId: [
                        {required: true, message: 'タスク名称', trigger: 'blur'},
                    ],
                    identifier: [
                        {required: true, message: '識別子', trigger: 'blur'},
                    ],
                    requestUrl: [
                        {required: true, message: '請求URL', trigger: 'blur'},
                    ],
                    requestParameter: [
                        {required: true, message: 'パラメータ', trigger: 'blur'},
                        {validator: isJsonString, trigger: 'blur' }
                    ]
                }
            }
        },
        mounted() {
            for (let key in this.form) {
                this.form[key] = this.info[key]
            }
            this.getJobs();
        },
        methods: {
            async getJobs() {
                let res = await this.$http.batchRecord.masterList();
                if (res.code === 200) {
                    this.options = res.rows;
                }
            },
            /**
             * キャンセル
             */
            cancel() {
                this.$emit('cancel')
            },
            /**
             * 保存
             */
            async save() {
                this.$refs.form.validate(async (valid) => {
                  if (valid) {
                      let res = await this.$http.supplier.functionEdit(this.form);
                      if (res.code === 200) {
                          this.$emit('save')
                          this.$message.success(res.msg)
                      }
                  }
                })
            }
        }
    }
</script>

<style scoped>

</style>
