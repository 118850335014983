<template>
    <div>
        <sub-header :titles="title" @refresh="refresh"></sub-header>
        <div v-has-permi="['supplier:suppliers:query']" v-show="!cz_types" class="sub-search">
            <el-form ref="form" :model="form" :inline="true" style="text-align: left;">
                <el-form-item class="input-item">
                    <el-input v-model="form.name" style="width: 255px" placeholder="サプライヤー名を検索する"
                              suffix-icon="el-icon-search"
                    ></el-input>
                </el-form-item>
                <el-form-item class="select-item">
                    <el-select v-model="form.status" placeholder="全ての状態" style="width: 140px;">
                        <el-option v-for="(item, index) in options" :key="'m_'+index"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item class="date-item">
                    <el-date-picker
                            v-model="createDate"
                            type="daterange"
                            style="width: 270px"
                            align="center"
                            range-separator="-"
                            start-placeholder="開始日付"
                            end-placeholder="終了日付"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" icon="el-icon-search" @click="search">検索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button icon="el-icon-refresh-right" @click="reForm">クリア</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="content" :class="{
            h1: cz_types,
            h2: !cz_types
        }">
            <el-row v-show="cz_types" style="text-align: left">
                <el-button type="text" icon="el-icon-back" class="btn-width-sm" style="color: gray" @click="backList">戻る
                </el-button>
            </el-row>
            <el-row v-show="!cz_types" style="text-align: left">
                <el-button type="success" icon="el-icon-plus" v-hasPermi="['supplier:suppliers:add']" @click="add"
                           class="text-btn3">追加
                </el-button>
            </el-row>
            <el-row v-has-permi="['supplier:suppliers:query']" style="margin-top: 20px;">
                <el-table v-show="!cz_types" :data="tableData" style="width: 100%;" :max-height="tableHeight">
                    <el-table-column align="center" prop="code" label="CODE" width="80" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column align="center" prop="name" label="名称" width="110" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column align="center" prop="identifier" label="識別子" width="140" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column align="center" prop="remark" label="備考" width="140">
                        <template slot-scope="scope">
                            <cus-tooltip :val="scope.row.remark"></cus-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="状態" width="80">
                        <template slot-scope="scope">
                            <el-switch style="display: block" v-model="scope.row.status" active-color="#13ce66"
                                       @change="supChangeStatus(scope.row)"
                                       inactive-color="rgba(242, 242, 242, 1)">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="createTime" label="作成時間" width="150">
                    </el-table-column>
                    <el-table-column align="center" label="操作">
                        <template slot-scope="scope">
                            <el-button v-has-permi="['supplier:suppliers:edit']" class="small-btn" type="primary"
                                       size="small" @click="edit(scope.row)">編集
                            </el-button>
                            <el-button class="small-btn" type="primary" size="small" @click="showInfo(scope.row)">機能
                            </el-button>
                            <el-button v-has-permi="['supplier:suppliers:remove']" class="small-btn" type="danger"
                                       size="small" @click="delSup(scope.row)">削除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination v-show="!cz_types" class="pagina-box" background layout="prev, pager, next"
                               :total="total" :current-page="pageNo"
                               :page-sizes="pagesizes"
                               :page-size="pagesize"
                               @current-change="onPage"
                               @size-change="onSizeChange">
                </el-pagination>
                <el-table v-show="cz_types" :data="tableData1" style="width: 100%" :max-height="tableHeight1">
                    <el-table-column align="center" prop="functionName" label="機能名称" width="180" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column align="center" prop="taskName" label="タスク名称" width="180">
                    </el-table-column>
                    <el-table-column align="center" prop="identifier" label="識別子" width="180" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column align="center" prop="requestUrl" label="請求URL" width="180" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column align="center" label="状態" width="80">
                        <template slot-scope="scope">
                            <el-switch style="display: block" v-model="scope.row.status" active-color="#13ce66"
                                       @change="supFuncChangeStatus(scope.row)"
                                       inactive-color="rgba(242, 242, 242, 1)">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作">
                        <template slot-scope="scope">
                            <el-button class="small-btn" type="primary" size="small" @click="editInfo(scope.row)">編集
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination v-show="cz_types" class="pagina-box" background layout="prev, pager, next"
                               :total="total1" :current-page="pageNo1"
                               :page-sizes="pagesizes1"
                               :page-size="pagesize1"
                               @current-change="onPage1"
                               @size-change="onSizeChange1">
                </el-pagination>
            </el-row>
        </div>
        <el-dialog :title="dialog_title + (types === 'add' ? 'を追加' : 'を編集')" :visible.sync="dialogVisible" :width="'1000px'"
                   :close-on-click-modal="false" :show-close="false">
            <supplier-add v-if="dialogVisible" :types="types" :info="editRow" @cancel="dialogVisible = false;"
                          @save="supSave"></supplier-add>
        </el-dialog>
        <el-dialog :visible.sync="funcDialog" width="30%"
                   :close-on-click-modal="false" :show-close="false">
            <span slot="title" style=""><h2>{{ funcRow.functionName }}</h2></span>
            <function-edit v-if="funcDialog" :info="funcRow" @cancel="funcDialog = false;"
                           @save="funcSave"></function-edit>
        </el-dialog>
    </div>
</template>

<script>
    import subHeader from '../../components/SubHeader'
    import supplierAdd from '../../pages/components/supplier-add'
    import functionEdit from '../../pages/components/function-edit'
    import CusTooltip from "../../components/CusTooltip";
    export default {
        name: "supplier",
        components: {subHeader, supplierAdd, functionEdit,CusTooltip},
        data() {
            return {
                funcRow: {},
                dialog_title: '',
                title: '',
                cz_types: '',
                editRow: {},
                types: '',
                tableHeight: 0,
                tableHeight1: 0,
                dialogVisible: false,
                funcDialog: false,
                options: [
                    {
                        label: '全ての状態',
                        value: ''
                    }, {
                        label: '正常',
                        value: '0'
                    }, {
                        label: '禁止',
                        value: '1'
                    },
                ],
                createDate: [],
                form: {
                    name: '',
                    status: '',
                    createTime: '',
                    params: {
                        beginTime: '',
                        endTime: ''
                    }
                },
                total: 0,
                pageNo: 1,
                pagesize: 10,
                pagesizes: [10, 20, 30, 50, 100, 200, 500],
                tableData: [],
                total1: 0,
                pageNo1: 1,
                pagesize1: 10,
                pagesizes1: [10, 20, 30, 50, 100, 200, 500],
                tableData1: [],
            }
        },
        mounted() {
            this.title = 'サプライヤー管理'
            this.dialog_title = 'サプライヤー'
            // 容器現在の高さを取得し、テーブルの最大高さをリセットする
            this.getTableMaxHeight();
            let _this = this;
            window.onresize = function () {//テーブルを高度に適応させるための方法
                _this.getTableMaxHeight();//容器現在の高さを取得し、テーブルの最大高さをリセットする
            }
            this.getSups();
        },
        methods: {
            /**
             * 検索
             * */
            search() {
                this.pageNo = 1;
                this.getSups();
            },
            /**
             * サプライヤ一覧を取得する
             * */
            async getSups() {
                this.form.pageNum = this.pageNo;
                this.form.pageSize = this.pagesize;
                if (this.createDate) {
                    this.form.params.beginTime = this.createDate[0];
                    this.form.params.endTime = this.createDate[1];
                } else {
                    this.form.params.beginTime = "";
                    this.form.params.endTime = "";
                }
                let res = await this.$http.supplier.list(this.form);
                this.tableData = [];
                if (res.code === 200) {
                    this.total = res.total;
                    res.rows.forEach(item => {
                        item.status = item.status === '0'
                        this.tableData.push(item);
                    })
                }
            },
            /**
             * クエリ条件をリセットする
             **/
            reForm() {
                this.form = {
                    nickName: '',
                    status: '',
                    createTime: '',
                    params: {
                        beginTime: '',
                        endTime: ''
                    }
                }
                this.createDate = []
            },
            onPage(num) {
                this.pageNo = num;
                this.getSups()
            },
            // 每页显示数量改变事件
            onSizeChange(size) {
                this.pageNo = 1;
                this.pagesize = size;
                this.getSups()
            },
            onPage1(num) {
                this.pageNo1 = num;
                this.getSupFunc()
            },
            // 每ページ表示数量は事件を変更する
            onSizeChange1(size) {
                this.pageNo1 = 1;
                this.pagesize1 = size;
                this.getSupFunc()
            },
            /**
             * 状態は事件を変更する
             * */
            async supChangeStatus(row) {
                let res = await this.$http.supplier.changeStatus({
                    supplierId: row.supplierId,
                    status: row.status ? '0' : '1'
                });
                if (res.code == 200) {
                    this.$message.success(res.msg)
                }
            },
            /**
             * 機能状態は事件を変更する
             * */
            async supFuncChangeStatus(row) {
                let res = await this.$http.supplier.functionUpdate({
                    supplierId: row.supplierId,
                    functionId: row.functionId,
                    status: row.status ? '0' : '1'
                });
                if (res.code == 200) {
                    this.$message.success(res.msg)
                } else {
                    row.status = false;
                }
            },
            /**
             * 新增
             */
            add() {
                this.types = 'add';
                this.dialogVisible = true;
            },
            /**
             * 編集
             */
            edit(row) {
                this.editRow = row;
                this.types = 'edit';
                this.dialogVisible = true;
            },
            // TODO 中文
            /**
             * 新增完成
             */
            supSave() {
                this.dialogVisible = false;
                this.getSups();
            },
            /**
             *
             * */
            funcSave() {
                this.funcDialog = false;
                this.getSupFunc();
            },
            // TODO 中文
            /**
             * 刷新
             * */
            refresh() {
                if (this.cz_types) {
                    this.getSupFunc();
                } else {
                    this.getSups();
                }
            },
            // TODO 中文
            /**
             * 編集详细
             */
            editInfo(row) {
                this.funcRow = row;
                this.funcDialog = true;
            },
            showInfo(row) {
                this.editRow = row;
                this.cz_types = 'info';
                this.title = 'サプライヤー機能'
                this.getSupFunc();
            },
            /**
             * 削除
             */
            delSup(row) {
                this.$confirm('削除は確定ですか ?', '提示', {
                    confirmButtonText: '確定',
                    cancelButtonText: 'キャンセル',
                    type: 'warning'
                })
                    .then(() => {
                        this.toDelete(row);
                    })
                    .catch(() => {
                    })
            },
            async toDelete(row) {
                let res = await this.$http.supplier.del({supplierId: row.supplierId})
                if (res.code === 200) {
                    this.$message.success(res.msg)
                    this.dialogVisible = false;
                    this.getSups();
                }
            },
            async getSupFunc() {
                let res = await this.$http.supplier.functionList({
                    supplierId: this.editRow.supplierId,
                    pageNum : this.pageNo1,
                    pageSize : this.pagesize1
                });
                this.tableData1 = [];
                if (res.code === 200) {
                    this.total1 = res.total;

                    res.rows.forEach(item => {
                        item.status = item.status === '0'
                        this.tableData1.push(item);
                    })
                }
            },
            backList() {
                this.cz_types = '';
                this.title = ''
                this.pageNo1 = 1;
                this.pagesize1 = 10;
            },
            // TODO 中文
            //获取容器当前高度，重设表格的最大高度
            getTableMaxHeight() {
                this.$nextTick(function () {
                    let box = document.querySelector(".content").attributes
                    let box_clientHeight = box[0].ownerElement.clientHeight;
                    this.tableHeight = box_clientHeight - 160;
                    this.tableHeight1 = box_clientHeight - 160;
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

  .sub-search {
    height: 40px;
    background: white;
    box-shadow: 0px 0px 15px rgba(170, 170, 170, 35%);
    padding: 18px 30px;
    margin-bottom: 20px;

    > > .el-input__prefix {
      color: #aaaaaa;
    }

    /deep/ .el-form-item__content {
      margin-left: 0px !important;
    }

    .input-item {
      /deep/ .el-input__suffix {
        left: 5px;
        right: auto;
      }

      /deep/ .el-input--suffix .el-input__inner {
        padding-right: 10px;
        padding-left: 30px;
      }
    }

    .date-item {
      /deep/ .el-input__prefix {
        top: 1px;
        left: 1px;
        height: 38px;
        -webkit-transition: all .3s;
        transition: all .3s;
        width: 40px;
        background: rgba(242, 242, 242, 1);
      }

      /deep/ .el-input__inner {
        padding-left: 45px;

      }
    }
  }

  .h1 {
    height: calc(100vh - 284px);
  }

  .h2 {
    height: calc(100vh - 380px);
  }

  .content {
    box-shadow: 0px 0px 15px rgba(170, 170, 170, 35%);
    padding: 30px 30px;
    background: rgba(255, 255, 255, 1);

    .pagina-box {
      margin-top: 20px;
      text-align: right;
    }
  }

  /deep/ .el-header {
    padding: 0;
    box-shadow: rgba(170, 170, 170, 35%) 0px 0px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /deep/ .el-button {
    font-weight: 400;
    padding: 12px 12px;
    border-radius: 0px;
    border: 1px solid #f2f2f2;
  }

  /deep/ .el-input__inner {
    border: 1px solid #f2f2f2;
  }

  /deep/ .el-dialog__footer {
    text-align: center;
  }

  /deep/ .el-dialog__header {
    text-align: left;
  }

  /deep/ .el-dialog__body {
    padding-bottom: 0px;
  }

  /deep/ .el-dialog__footer {
    padding-top: 0px;
    margin-top: -5px;
    padding-bottom: 30px;

    .el-button:nth-child(2) {
      background: rgba(102, 190, 150, 1);
    }
  }

  > > > .el-table {
    .el-table__body-wrapper {
      height: calc(100% - 48px) !important;
    }
  }

  .small-btn {
    color: white;
    height: 24px !important;
    padding: 9px 15px !important;
    line-height: 1px;
  }

</style>
